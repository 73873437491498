@import "Assets/fonts/Fonts.css";
.navbar {
  width: auto;
  padding: 0px 80px;
  padding-bottom: 20px;
}
.navbar .header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: Juliett;
  font-size: 60px;
  letter-spacing: 20px;
  margin-right: -20px;
  text-align: center;
  padding: 20px;
  word-break: normal;
  gap: 2px 20px;
  text-decoration: none;
  color: black;
}
.navbar .header:visited {
  color: unset;
}
.navbar .header span {
  display: flex;
  align-items: center;
  line-height: 1;
  text-shadow: 1px 4px 4px rgba(0, 0, 0, 0.1);
}
.navbar .header .dunedin {
  font-family: "Berkhai Blackletter";
  padding-top: 10px;
  font-size: 75px;
}
.navbar .icons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 20px 40px;
}
.navbar .icons button {
  border: none;
  background-color: transparent;
  width: fit-content;
  padding: 0px;
}
.navbar .icons button:hover {
  cursor: pointer;
}
.navbar .icons svg {
  width: 40px;
  height: 40px;
}
.navbar .links-container {
  display: flex;
  justify-content: center;
}
.navbar .links-container .links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px 40px;
  font-size: 20px;
}
.navbar .links-container .links a {
  border: 10px solid transparent;
  text-decoration: none;
  color: black;
}
.navbar .links-container .links a:hover {
  color: rgba(40, 40, 40);
}
.navbar .border {
  margin-top: 5px;
  border-bottom: 1px solid black;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(100, 88, 88, 0.1);
  -moz-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 1024px) {
  .navbar {
    padding: 0px 60px;
    padding-bottom: 40px;
  }
  .navbar .header {
    font-size: 45px;
    gap: 2px 10px;
  }
  .navbar .header .dunedin {
    padding-top: 5px;
    font-size: 55px;
  }
}
@media only screen and (max-width: 480px) {
  .navbar {
    padding: 0px 40px;
    padding-bottom: 40px;
  }
  .navbar .header {
    padding: 20px 0px;
    font-size: 35px;
    gap: 2px 7px;
    letter-spacing: 15px;
    margin-right: -15px;
  }
  .navbar .header .dunedin {
    padding-top: px;
    font-size: 45px;
  }
  .navbar .links-container .links {
    font-size: 16px;
  }
}
.home .missionStatement {
  font-family: "Segoe UI";
  display: flex;
  justify-content: center;
}
.home .missionStatement div {
  max-width: 800px;
}
.home .bongs {
  padding-top: 20px;
}
.home .bongs h1 {
  font-family: Juliett;
  letter-spacing: 10px;
  margin-right: -10px;
  font-weight: 500;
}
.comingSoon {
  font-family: Juliett;
  letter-spacing: 10px;
  font-size: 40px;
}
.items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
}
.items-container .item {
  width: 280px;
  height: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.1);
  background-color: #d8d8d8;
}
.items-container .item img {
  background-color: black;
  width: 280px;
  height: 280px;
  border-radius: 8px 8px 0px 0px;
}
.items-container .item .price {
  font-size: 20px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about .text {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
}
.about .text .textcontent {
  max-width: 800px;
}
.about .text .textcontent .section {
  margin-bottom: 25px;
}
.about .text .textcontent .section .intro {
  font-size: 40px;
  letter-spacing: 13px;
  margin-right: -13px;
}
.about .text .textcontent .section h1 {
  margin: 12px 0px;
  font-family: Juliett;
  letter-spacing: 10px;
  margin-right: -10px;
}
.about .text .textcontent .section ul {
  padding-left: 20px;
}
.about .text .textcontent .section ul li {
  padding-bottom: 10px;
}
.about .mapContainer {
  height: 200px;
  width: auto;
}
.about .mapContainer #gmap_canvas {
  width: 100%;
  border: none;
}
@media only screen and (max-width: 1024px) {
  .about .text .textcontent .section .intro {
    font-size: 36px;
    letter-spacing: 11px;
    margin-right: -11px;
  }
  .about .text .textcontent .section h1 {
    font-size: 28px;
    letter-spacing: 8px;
    margin-right: -8px;
  }
}
@media only screen and (max-width: 480px) {
  .about .text .textcontent .section {
    font-size: 14px;
  }
  .about .text .textcontent .section .intro {
    font-size: 32px;
    letter-spacing: 8px;
    margin-right: -8px;
  }
  .about .text .textcontent .section h1 {
    font-size: 24px;
    letter-spacing: 8px;
    margin-right: -8px;
  }
}
.footer {
  background-color: black;
  color: white;
  height: 150px;
  margin-top: 30px;
  min-height: 100px;
  box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.2);
}
.tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.tile-container .out-of-stock {
  font-family: juliett;
  letter-spacing: 3px;
}
.dropdown-container {
  position: relative;
}
.dropdown-container .dropdown {
  border: 1px solid rgba(206, 206, 206, 0.438);
  position: absolute;
  width: 160px;
  left: -60px;
  background-color: #ececec;
  box-shadow: 1px 1px 30px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.dropdown-container .dropdown .items-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dropdown-container .dropdown .items-container button {
  font-size: 16px;
  width: 100%;
  padding: 10px;
}
.dropdown-container .dropdown .items-container button:hover {
  background: #dfdfdf;
}
.dropdown-container .dropdown .items-container button:first-child {
  border-radius: 10px 10px 0px 0px;
}
.dropdown-container .dropdown .items-container button:last-child {
  border-radius: 0px 0px 10px 10px;
}
.dropdown-container .dropdown .items-container button:first-child:last-child {
  border-radius: 10px 10px 10px 10px;
}
.dropdown-container .dropdown .items-container button:not(:last-child):after {
  content: "";
  position: absolute;
  padding: 14px;
  left: 15px;
  right: 15px;
  border-bottom: 1px solid #acacac;
}
.tile {
  padding: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tile-image-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.tile-image {
  height: 300px;
  width: fit-content;
  box-shadow: 0px 30px 5px rgba(0, 0, 0, 0.5);
}
.tile-content {
  gap: 15px;
  word-wrap: break-word;
  flex-grow: 1;
  font-family: Juliett;
  letter-spacing: 3px;
  font-size: 22px;
  display: flex;
  flex-direction: column;
}
.tile-content h3 {
  margin: unset;
}
.add-to-cart-container {
  display: flex;
  justify-content: center;
}
.add-to-cart {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: unset;
  border: unset;
}
.add-to-cart .price {
  font-family: Juliett;
  font-size: 32px;
  letter-spacing: 3px;
}
.add-to-cart::after {
  position: relative;
  bottom: 20px;
  right: 24px;
  font-size: 24px;
  line-height: 1px;
  width: 0px;
}
.add-to-cart:hover {
  cursor: pointer;
}
.add-to-cart svg {
  width: 40px;
  height: 40px;
}
.popup-button {
  border: unset;
  background-color: unset;
  padding: unset;
}
.popped-image {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popped-image .img-container img {
  max-height: 90vh;
  max-width: 90vw;
}
.popped-image .close-button {
  position: fixed;
  top: 30px;
  right: 30px;
  font-size: 60px;
  border: unset;
  background-color: unset;
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}
.cart {
  width: 100%;
  font-family: Juliett;
  font-size: 20px;
  letter-spacing: 8px;
}
.shop-list-item {
  font-family: Juliett;
  font-size: 20px;
  letter-spacing: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.shop-list-item .popup-button {
  height: 100%;
}
.shop-list-item .image-container {
  height: 240px;
}
.shop-list-item .image-container .image {
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.shop-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px 30px;
  justify-content: space-evenly;
  flex-grow: 1;
  flex-wrap: wrap;
}
.shop-list-item-content .content-title {
  max-width: 500px;
  margin: 10px 0px;
}
.shop-list-item-content .quantity-section {
  min-width: 150px;
}
.shop-list-item-content .quantity-section .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.shop-list-item-content .quantity-section .buttons span {
  font-size: 28px;
}
.shop-list-item-content .quantity-section .buttons button {
  padding: 0px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shop-list-item-content .quantity-section .buttons button:disabled svg {
  opacity: 0.3;
}
.shop-list-item-content .quantity-section .buttons button svg {
  width: 80%;
  height: 80%;
}
.shop-list-item-content .total-price {
  min-width: 180px;
  margin: unset;
}
.shop-list-item-content .total-price .total {
  font-size: 20px;
  font-weight: normal;
}
.shop-list-item-content .remove-item {
  border: none;
  width: fit-content;
  background-color: transparent;
}
.shop-list-item-content .remove-item svg {
  width: 20px;
  height: 20px;
  fill: rgba(200, 0, 0);
}
.shop-list-item-checkout .image-container {
  height: 50px;
}
.shop-list-item-checkout .shop-list-item-content {
  gap: 2px 15px;
}
.shop-list-item-checkout .shop-list-item-content .content-title {
  font-size: 14px;
}
.shop-list-item-checkout .shop-list-item-content .quantity-section {
  font-weight: bold;
  font-size: 10px;
}
.shop-list-item-checkout .shop-list-item-content .quantity-section .buttons span {
  font-size: 16px;
}
.shop-list-item-checkout .shop-list-item-content .total-price {
  font-size: 14px;
}
.shop-list-item-checkout .shop-list-item-content .total-price .total {
  font-weight: bold;
  font-size: 10px;
}
.checkout-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.checkout-container .checkout-link {
  background-color: #359b2c;
  text-decoration: none;
  color: white;
  padding: 15px 50px;
  max-width: 200px;
}
.checkout-container .checkout-link:hover,
.checkout-container .checkout-link:focus {
  text-decoration: underline;
}
@media only screen and (max-width: 605px) {
  .shop-list-item {
    flex-wrap: wrap;
    gap: unset;
  }
}
.checkout .grand-total {
  font-family: Juliett;
  font-size: 22px;
  letter-spacing: 8px;
  padding-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.checkout .checkout-form {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  margin-bottom: 80px;
}
.checkout .checkout-form label {
  font-family: Juliett;
  letter-spacing: 4px;
  font-size: 24px;
  padding: 5px 0px;
  text-align: left;
}
.checkout .checkout-form input {
  font-family: SegoeUI;
  width: 100%;
  width: calc(100% - 14px);
  border: none;
  border: 1px solid black;
  padding: 6px;
  font-size: 24px;
}
.checkout .checkout-form .submitButton {
  margin-top: 20px;
  background-color: #3053b1;
  text-decoration: none;
  color: white;
  height: 50px;
  width: 100%;
  font-family: Juliett;
  letter-spacing: 4px;
  font-size: 26px;
}
.checkout .checkout-form .submitButton:hover,
.checkout .checkout-form .submitButton:focus {
  text-decoration: underline;
}
.checkout .checkout-form-container {
  display: flex;
  justify-content: center;
}
.order-placed {
  font-family: Juliett;
  letter-spacing: 3px;
}
.order-placed .order-success {
  padding-bottom: 20px;
}
html,
#root,
body,
.app {
  height: 100%;
}
body {
  text-align: center;
  background-color: #f3f3f3;
  font-family: "Segoe UI";
}
.ageWarning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.ageWarning .warning-container {
  width: 50%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}
.ageWarning .warning-container .only {
  color: red;
}
.ageWarning .warning-container .buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
.ageWarning .warning-container .buttonContainer button {
  width: 100px;
  min-width: fit-content;
  padding: 8px;
  margin-top: 20px;
  border: none;
  background-color: #e2e2e2;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(100, 88, 88, 0.1);
  -moz-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
}
.ageWarning .warning-container .buttonContainer button:hover,
.ageWarning .warning-container .buttonContainer button :focus {
  cursor: pointer;
  background-color: #e4e4e4;
}
.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}
.content {
  padding: 0px 80px;
  display: flex;
  justify-content: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media only screen and (max-width: 1024px) {
  .content {
    padding: 0px 60px;
  }
}
@media only screen and (max-width: 480px) {
  .content {
    padding: 0px 40px;
  }
}
button:hover {
  cursor: pointer;
}
